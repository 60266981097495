import React, { useState, useEffect } from "react"
import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/layout"
import { Link } from "gatsby"

import SEO from "@accrosoft-ltd/accropress-websites-components/dist/esm/seo"
import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader"

import "@accrosoft-ltd/vf-careers-site-theme/src/components/wig-bundle.css"

import fetchJsonp from "fetch-jsonp"

require("es6-promise").polyfill()

export default function StandardPagePage(props) {
  const {
    pageContext: {
      integratorData,
      contentMetaData,
      contentDataData,
      config,
      navResultData,
    },
  } = props

  const siteConfig = config

  const [apiKey, setApiKey] = useState(siteConfig.apiKey || props.smartfeedID)
  const [gdprKey, setGdprKey] = useState(siteConfig.gdprKey || props.apiKey || props.smartfeedID)
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group ? "group" : "id"
  )
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({})
  const [appGatewayURL, setAppGatewayURL] = useState({})
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true)
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null)

  useEffect(() => {
    if (apiKey) {
      console.log(apiKey)
      let companyDetailsEndpoint = "GetCompanyDetails";

      if (groupOrIdParam === "group") {
        companyDetailsEndpoint = "GetGroupDetails";
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
          "/CareerPage/" +
          companyDetailsEndpoint +
          "?id=" +
          apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json()
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
              "/CareerPage/" +
              "GetApplicantGetwayURL" +
              "?companyId" +
              "=" +
              gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json()
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false)
                setSmartfeedCompanyData(jsonList)
                setAppGatewayURL(appgatway)
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false)
                setVacanciesRouteError(ex)
                console.error(ex, "error")
              })
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false)
            setVacanciesRouteError(ex)
            console.error(ex, "error")
          })
      } catch (ex) {
        setVacanciesRouteLoading(false)
        setVacanciesRouteError(ex)
        console.error(ex, "error")
      }
    } else {
      setVacanciesRouteLoading(false)
      setVacanciesRouteError("No API key was provided.")
    }
  }, [apiKey, groupOrIdParam])

  const returnParentArray = (id) => {
    if (id) {
      if (
        navResultData &&
        navResultData.menu &&
        navResultData.menu.length > 0
      ) {
        let parentArrayData = navResultData.menu.filter(function (item) {
          return item.id === id
        })

        return parentArrayData[0]
      } else {
        return {}
      }
    } else {
      return {}
    }
  }

  let pageParentDataArray = {}

  if (contentMetaData && contentMetaData.parent && contentMetaData.parent.id) {
    pageParentDataArray = returnParentArray(contentMetaData.parent.id)
  }

  function dynamicSort(property) {
    var sortOrder = 1
    if (property[0] === "-") {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      return result * sortOrder
    }
  }

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={apiKey}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      path={siteConfig.companyCareersSite ? "/" : "/"}
      {...props}
    >
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />

      {contentDataData && contentDataData.length > 0 ? (
        <div className="main-page-wrapper loaded">
          <div
            className="banner page"
            style={{
              backgroundImage: `linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.7),
                rgba(0, 0, 0, 0.7)
              ),
            `,
            }}
          >
            <div className="container">
              <div className="bannerWrapper">
                <h1>{contentMetaData.title}</h1>
                <div className="breadcrumb">
                  <Link to="/">Home</Link> /{" "}
                  <span className="active">{contentMetaData.title}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mainContentWrapper page">
            <section className="app-content">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-12-m pageMain">
                    {contentMetaData.title !==
                      contentDataData[0].children[0].value && (
                        <h2>{contentDataData[0].children[0].value}</h2>
                      )}

                    <WigLoader
                      weducWebsitesID={siteConfig.weducWebsitesID}
                      weducContainer={siteConfig.weducContainerID}
                      weducEntity={siteConfig.weducEntityID}
                      contentDataData={contentDataData}
                      contentMetaData={contentMetaData}
                      contentDataArray="0"
                      contentDataChild="2"
                      AccroPressProdEndpoint={siteConfig.AccroPressProdEndpoint}
                      AccroPressLocalEndpoint={siteConfig.AccroPressLocalEndpoint}
                      integratorID={siteConfig.integratorID}
                      integratorAccessToken={siteConfig.integratorAccessToken}
                      imageFormatting={
                        process.env.IMAGE_FORMATTING_SECRET ||
                        process.env.GATSBY_IMAGE_FORMATTING_SECRET
                      }
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="main-page-wrapper loading"></div>
      )}
    </Layout>
  )
}
